<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/vice-dean-research' }">
        <app-i18n code="entities.viceDeanResearch.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n
          code="entities.viceDeanResearch.view.title"
        ></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n
          code="entities.viceDeanResearch.view.title"
        ></app-i18n>
      </h1>

      <div
        class="app-page-spinner"
        v-if="loading"
        v-loading="loading"
      ></div>

      <app-vice-dean-research-view-toolbar
        v-if="record"
      ></app-vice-dean-research-view-toolbar>

      <el-form
        :label-position="labelPosition"
        :label-width="labelWidthForm"
        @submit.prevent.native
        class="form"
        v-if="record"
      >
        <app-view-item-relation-to-one
          :label="fields.faculty.label"
          :readPermission="fields.faculty.readPermission"
          :navigatePermission="fields.faculty.navigatePermission"
          :url="fields.faculty.viewUrl"
          :value="presenter(record, 'faculty')"
        ></app-view-item-relation-to-one>

        <app-view-item-relation-to-many
          :label="fields.user.label"
          :readPermission="fields.user.readPermission"
          :navigatePermission="fields.user.navigatePermission"
          :url="fields.user.viewUrl"
          :value="presenter(record, 'user')"
        ></app-view-item-relation-to-many>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ViceDeanResearchViewToolbar from '@/modules/vice-dean-research/components/vice-dean-research-view-toolbar.vue';
import { ViceDeanResearchModel } from '@/modules/vice-dean-research/vice-dean-research-model';

const { fields } = ViceDeanResearchModel;

export default {
  name: 'app-vice-dean-research-view-page',

  props: ['id'],

  components: {
    [ViceDeanResearchViewToolbar.name]: ViceDeanResearchViewToolbar,
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      record: 'viceDeanResearch/view/record',
      loading: 'viceDeanResearch/view/loading',
    }),

    fields() {
      return fields;
    },
  },

  async created() {
    await this.doFind(this.id);
  },

  methods: {
    ...mapActions({
      doFind: 'viceDeanResearch/view/doFind',
    }),

    presenter(record, fieldName) {
      return ViceDeanResearchModel.presenter(record, fieldName);
    },
  },
};
</script>
